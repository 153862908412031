import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Yoga";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Μέσα από βασικές αλληλουχίες της Yoga θα απελευθερώσουμε τους μύες και
          τις αρθρώσεις και θα νιώσουμε ελευθερία εσωτερικά και εξωτερικά.
        </P>
        <P>
          Η Υοga απευθύνεται σε όλους. Τονώστε το σώμα σας, νιώσετε όμορφα και
          γεμίστε τη μέρα σας με θετική ενέργεια!
        </P>
        <P>
          Ας κλείσουμε την πόρτα στο άγχος και το γρήγορο ρυθμό της
          καθημερινότητας εξερευνώντας το σώμα μας δίνοντάς του αυτό που
          πραγματικά έχει ανάγκη.
        </P>
        <P>To μάθημα περιλαμβάνει τις τεχνικές Vinyasa και Hatha Yoga.</P>
      </Section.Main>
    </Layout>
  );
}
